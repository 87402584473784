import {computedFrom, inject, LogManager} from "aurelia-framework";
import {ConditionMatcher} from "../../condition-builder/condition-matcher";
import {Client} from '../../api/client';
import {BindingSignaler} from "aurelia-templating-resources";
import * as _ from 'lodash';
import {selectable} from "./decorators/selectable";
import {ExecEditOnItemClick} from "./mixins/exec-edit-on-item-click";

import "./tile-renderer.less";

const logger = LogManager.getLogger('TableItemTileRenderer');
//logger.setLevel(LogManager.logLevel.none); // Disable logging for this class by setting "LogManager.logLevel.none"

@selectable()
@inject(
    ConditionMatcher,
    Client,
    BindingSignaler,
    ExecEditOnItemClick
)
export class TableItemTileRenderer {

    constructor(
        conditionMatcher,
        client,
        signaler,
        execEditOnItemClick
    ) {
        this.conditionMatcher = conditionMatcher;
        this.client = client;
        this.signaler = signaler;
        this.execEditOnItemClick = execEditOnItemClick;
    }

    activate(context)
    {
        Object.assign(this, context);

        if (this.data && this.data.items) {
            this._prepareFiles();
        }
    }

    @computedFrom('options', 'config')
    get columnCount()
    {
        let count =  this.config ? this.config.visibleColumns.length : 0;

        if (this.config.selectable) {
            count++;
        }

        return count + 1;
    }

    _prepareFiles()
    {
        let fetches = [];

        _.each(this.data.items, (item) => {

            logger.debug('preparing file', item);

            let thumbnail = this._getThumbnailObject(item);

            if (thumbnail.downloadUrl || thumbnail.previewUrl) {
                return false;
            }

            fetches.push(thumbnail.id);
        });

        if (fetches.length == 0) {
            return;
        }

        let conditions = {
            id: {
                $in: fetches
            }
        };

        this.client.get('file/file?conditions=' + JSON.stringify(conditions)).then(fileData => {

            let files = fileData.items;

            _.each(this.data.items, (item) => {

                let index = _.findIndex(files, (e) => {
                    let thumbnail = this._getThumbnailObject(item);

                    return e.id === thumbnail.id;
                });

                if (index > -1) {
                    _.set(item, this.config.renderer.tile.previewField, files[index]);
                }
            });

            this.signaler.signal('sio-files-loaded');
        });
    }

    getTitle(item)
    {
        if (null == this.config.renderer.tile.titleField) {
            // Do not render anything if title field was not specified
            return '';
        }

        if (!_.has(item, this.config.renderer.tile.titleField)) {
            // If field doesn't exist on item, then
            // try to render titleField as inline code

            return this.config.renderer.tile.titleField;
        }

        return _.get(item, this.config.renderer.tile.titleField);
    }

    _getThumbnailObject(item) {
        if (item && _.has(item, this.config.renderer.tile.previewField)) {
            return _.get(item, this.config.renderer.tile.previewField);
        } else {
            return null;
        }
    }

    getMimeType(item) {
        return item.file.mimeType;
    }

    getThumbnail(item) {
        let file = this._getThumbnailObject(item);

        if (file && file.previewUrl) {
            return file.previewUrl.replace('$width', 200).replace('$height', 150);
        } else if (file && file.downloadUrl) {
            return file.downloadUrl;
        } else {
            return null;
        }
    }

    onItemClick(event, item, options)
    {
        this.selectRow(item);
    }

    hasEditAction(item, options)
    {
        if (options && !options.showActions) {
            return false;
        }

        return this.execEditOnItemClick.hasEditAction(item.actions);
    }
}

import {inject, LogManager} from "aurelia-framework";
import {BindingSignaler} from "aurelia-templating-resources";
import {UrlUtils} from "../../utilities/url-utils.js";
import {WindowUtils} from "../../utilities/window-utils";
import * as _ from "lodash";
import {debounce} from '../../utilities/debounce';
import {FilterFormFieldFactory} from "./filter-service-helpers/filter-form-field-factory";
import {FilterValueToConditionsTransformer} from "./filter-service-helpers/filter-value-to-conditions-transformer";

import "./filter-form-field.less";

const logger = LogManager.getLogger('table');

@inject(
    BindingSignaler,
    FilterFormFieldFactory,
    FilterValueToConditionsTransformer
)
export class FilterService {
    filters = [];
    filterFields = {};

    activeFilters = [];

    onFilterChange = (items) => {
    };

    constructor(
        signaler,
        filterFormFieldFactory,
        filterValueToConditionsTransformer
    ) {
        this.signaler = signaler;
        this.filterFormFieldFactory = filterFormFieldFactory;
        this.filterValueToConditionsTransformer = filterValueToConditionsTransformer;

        this.debounce = debounce((reload) => {
            let conditions = this.getConditions();

            this.saveFiltersToUrlParams();

            this.onFilterChange(conditions, reload);
            this.signaler.signal('sio-table-filter-change');
        }, 250);
    }

    initialize(tableInstance, config, saveFiltersToUrl) {
        let filtersEnabledByDefault;
        this.tableInstance = tableInstance;
        this.saveFiltersToUrl = !!saveFiltersToUrl;

        // Processing of filter buttons of table
        if (config.filters && 0 !== config.filters.length) {

            let visibleFilters = tableInstance.viewSettingsService.config?.activeView?.enabledFilters;
            if (Array.isArray(config.filters)) {
                this.filters = config.filters.filter(filter => {

                    if (visibleFilters != null) {
                        return visibleFilters.indexOf(filter.id) > -1;
                    }

                    return true;
                });
            }

            filtersEnabledByDefault = _.filter(this.filters, filter => {
                return filter.activated === true;
            });
            filtersEnabledByDefault = _.map(filtersEnabledByDefault, filter => filter.id);
        }

        let filters = this.loadFiltersFromUrlParams();
        let filterValues = filters.filterValues || {};
        this.activeFilters = filters.activeFilters || filtersEnabledByDefault;

        this.filterFields = {};

        _.each(config.visibleColumns, (column) => {

            // @fixme address should not be processed in such hackerish way
            let filterValue = !config.elasticsearch && (column.filter === 'address' || column.type === 'address') ?
                filterValues['$text'] :
                filterValues[column.property];

            if (!filterValue) {
                filterValue = null;
            }

            if (column.hasOwnProperty('options') && column.options.hasOwnProperty('choice')) {
                column.filter = 'choice';
            }

            const formField = this
                .filterFormFieldFactory
                .createFormField(this, column, filterValue, config.elasticsearch);

            if (formField) {
                this.filterFields[column.property] = formField;
            }
        });
    }

    reset() {
        _.forEach(this.filterFields, (field) => {
            field.resetValue();
        });
    }

    change(field) {
        logger.debug('<FilterService> Changed filter fields (value changed)', field, this.filterFields);

        if (['choice'].indexOf(field.type) !== -1) {
            this.debounce(true);
        } else {
            this.debounce(this.tableInstance.shouldSearch());
        }
    }

    keyUp(event) {
        logger.debug('<FilterService> Key up', event, this.filterFields);

        if (event.keyCode === 13 /*Enter*/) {
            this.debounce(true);
        }
    }

    wrapFiltersInGroup(filters) {
        if (!filters || filters.length === 0) {
            return [];
        }

        let groups = {};

        filters.forEach(filter => {
            const radioGroup = filter.radioGroup ?? filter.optionalGroup ?? '__no_group__';

            if (groups[radioGroup] === undefined) {
                groups[radioGroup] = {
                    filters: [],
                    name: radioGroup
                };
            }

            groups[radioGroup].filters.push(filter);
        });

        let groupsArray = [];
        _.forEach(groups, group => {
            groupsArray.push(group);
        });

        return groupsArray;
    }

    toggleFilter(targetFilter) {
        let index = this.activeFilters.indexOf(targetFilter.id);

        if (null != targetFilter.radioGroup) {
            const allFromSameRadioGroup = this.filters.filter(
                (filter) => {
                    return filter.radioGroup === targetFilter.radioGroup && filter.id !== targetFilter.id;
                }
            );

            // Remove all filters from same radio group

            allFromSameRadioGroup.forEach(
                filter => {
                    const index = this.activeFilters.indexOf(filter.id);

                    if (index > -1) {
                        this.activeFilters.splice(index, 1);
                    }
                }
            );
        }

        if (index > -1) {
            this.activeFilters.splice(index, 1);
        } else {
            this.activeFilters.push(targetFilter.id);
        }

        this.debounce(true);
    }

    getConditions() {
        let activeFilterObjects = _.filter(this.filters, (e) => {
            return this.activeFilters.indexOf(e.id) > -1;
        });

        activeFilterObjects = _.groupBy(activeFilterObjects, (filter) => {
            return filter.radioGroup ?? filter.optionalGroup ?? '__no_group__';
        });

        let conditions = _.map(activeFilterObjects, (e) => {

            if (e[0].optionalGroup) {
                return {
                    $or: _.map(e, (i) => {
                        return i.conditions;
                    })
                };
            } else {
                return {
                    $and: _.map(e, (i) => {
                        return i.conditions;
                    })
                };
            }
        });

        let filterValues = _.map(this.filterFields, (field) => {

            const options = field?.fields?.[0]?.options ?? field?.options;

            return {
                property: field.property,
                value: field.getValue(),
                type: field.type,
                filterType: field.filterType,
                options: options,
            }
        });

        _.each(filterValues, (field) => {

            let value = field.value;
            let property = field.property;
            let type = field.filterType || field.type;

            const subConditions = this
                .filterValueToConditionsTransformer
                .transform(value, property, type, field?.options)
            ;

            if (subConditions) {
                conditions.push(subConditions);
            }
        });

        return {'$and': conditions};
    }

    isFilterActive(id) {
        return this.activeFilters.indexOf(id) > -1;
    }

    getFilterFieldForProperty(property) {
        if (this.filterFields[property]) {
            return this.filterFields[property];
        }

        return null;
    }

    loadFiltersFromUrlParams() {
        if (!this.saveFiltersToUrl) return {};

        let filterValues = UrlUtils.getQueryParamValue(window.location.href, 'filters');
        logger.debug('<FilterService> Decoded filters param from url', filterValues);

        if (!filterValues) return {};
        return filterValues;
    }

    saveFiltersToUrlParams() {
        if (!this.saveFiltersToUrl) return;

        let filtersToSave = {
            filterValues: _.reduce(
                this.filterFields,
                function (filters, field) {
                    filters[field.property] = field.getValue();
                    return filters;
                },
                {}
            ),
            activeFilters: this.activeFilters
        };

        logger.debug('<FilterService> Save filters to url', filtersToSave);

        let newurl = UrlUtils.upsertQueryParamValue(
            window.location.href,
            'filters',
            filtersToSave
        );

        WindowUtils.updateLocationHrefWithoutReloading(newurl);
    }

    removeFiltersFromUrlParams() {
        let url = new URL(window.location.href);

        if (url.searchParams.has('filters')) {
            url.searchParams.delete('filters')
        }
        WindowUtils.updateLocationHrefWithoutReloading(url.toString());
    }
}

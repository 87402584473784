import {bindable, customElement, inject, LogManager, NewInstance, PLATFORM} from "aurelia-framework";
import {ConfigurationLoader} from "./loader/configuration-loader";
import {TableService} from "./service/table-service";
import {RendererService} from "./service/renderer-service";
import {ViewSettingsService} from "./service/view-settings-service";
import {FilterService} from "./service/filter-service";
import {EventAggregator} from "aurelia-event-aggregator";
import {ConditionMatcher} from "../condition-builder/condition-matcher";
import {SelectService} from "./service/select-service";
import {UrlUtils} from "../utilities/url-utils";
import {Client} from "../api/client";
import * as _ from "lodash";
import {debounce} from '../utilities/debounce';
import {AuthTokenStorage} from "../auth/auth-token-storage";
import {WorkflowService} from "../workflow/workflow-service";

import "./table.less";
import {TabContentTable} from "../view-block/tab/presets/table";
import {StandardActions} from "../action/standard-actions";

const logger = LogManager.getLogger('table');

PLATFORM.moduleName('./renderer/cell-renderer');
PLATFORM.moduleName('./renderer/tile-renderer');
PLATFORM.moduleName('./renderer/calendar-renderer');

@inject(
    ConfigurationLoader,
    NewInstance.of(TableService),
    NewInstance.of(SelectService),
    NewInstance.of(RendererService),
    NewInstance.of(ViewSettingsService),
    NewInstance.of(FilterService),
    EventAggregator,
    ConditionMatcher,
    Client,
    Element,
    AuthTokenStorage,
    WorkflowService,
    StandardActions
)
@customElement('sio-table')
export class Table {
    @bindable saveFiltersToUrl;

    @bindable config;
    @bindable options;
    @bindable contextObjectRef;
    @bindable viewContext;
    @bindable additionalFetchConditions = {};

    constructor(
        configLoader,
        tableService,
        selectService,
        rendererService,
        viewSettingsService,
        filterService,
        ea,
        conditionMatcher,
        client,
        element,
        authTokenStorage,
        workflowService,
        actions
    ) {
        this.configLoader = configLoader;
        this.tableService = tableService;
        this.selectService = selectService;
        this.rendererService = rendererService;
        this.viewSettingsService = viewSettingsService;
        this.filterService = filterService;
        this.ea = ea;
        this.conditionMatcher = conditionMatcher;
        this.client = client;
        this.authTokenStorage = authTokenStorage;
        this.workflowService = workflowService;
        this.actions = actions;

        this.tableService.callbacks = {
            onLoadStart: this.onLoadStart.bind(this),
            onLoadSuccess: this.onLoadSuccess.bind(this),
            onLoadError: () => {
            },
        };

        this.rendererService.onCurrentRendererChange = this.onCurrentRendererChange.bind(this);
        this.selectService.onSelectionChange = this.onSelectionChange.bind(this);
        this.viewSettingsService.onViewChange = this.onViewChange.bind(this);
        this.filterService.onFilterChange = this.onFilterChange.bind(this);

        this.currentDOMInstance = element;

        this.__lastSearch = null;
        this.__search = debounce(
            function (value) {
                if (
                    this.isSearchImmediately() &&
                    this.__lastSearch === value
                ) {
                    return;
                }

                this.__lastSearch = value;
                this.tableService.search(value);
            },
            250
        );
    }

    @bindable onSelectionChanged = () => {
    };

    _defaultOptions = {
        showActions: true,
        showModelActions: true,
        multiSelect: true
    };

    //Config loading
    loading = false;

    _config;

    blacklistedNotice = false;

    tableService;

    @bindable onFiltersChanged = () => {
    };

    onFilterChange(conditions, reload) {
        // noinspection JSCheckFunctionSignatures
        logger.debug('Filter changes', conditions, reload);

        this.tableService.setFilters(conditions, reload);
        this.__notifyAboutFiltersChange(conditions);
    }

    onViewChange() {
        if (_.isString(this.config)) {
            this.client.removeCache(this.config + '/list');
        }
        this.configChanged();
    }

    onCurrentRendererChange(currentView) {
        if (this) {
            this.tableService.setCurrentRenderer(currentView);
            this.resetFilters();
        }
    }

    onSelectionChange(items, submit = false) {
        this.onSelectionChanged(items, submit);
    }

    onLoadStart() {
        this.data = null;
        this.selectService.unselect();
    }

    onLoadSuccess(data) {
        this.data = data;

        // @fixme find a general solution for this
        this.blacklistedNotice = false;
        if ('tourism/journey-participant' === this.config) {
            _.forEach(data.items, participant => {
                if (participant.customer && participant.customer.blacklist) {
                    this.blacklistedNotice = true;
                    return false;
                }
            })
        }
    }

    getItemIdsForWorkflow(items) {
        let id = [];

        _.forEach(items, item => {
            id.push(item.id);
        });

        return id;
    }

    getBulkOperations(selectedItems, config) {
        if (!selectedItems || selectedItems.length === 0) {
            return [];
        }

        const sameActions = function (a, b) {
            return JSON.stringify(a) === JSON.stringify(b);
        };

        let selectedItemsFull = this.getFullItems(selectedItems);

        // Collect only rowActions that each selected item have
        let rowActions = []
            .concat(selectedItemsFull[0].actions || [])
            .filter(rowAction => {
                let keep = false;

                selectedItemsFull.forEach(item => {
                    (item.actions || []).forEach(itemAction => {
                        if (sameActions(rowAction, itemAction)) {
                            keep = true;
                            return false;
                        }
                    });
                });

                return keep;
            })
        ;

        const bulkOperations = [].concat(config.bulkOperations || []);
        bulkOperations.push(
            {
                type: 'table-row-actions',
                actions: rowActions
            }
        );

        return bulkOperations;
    }

    getFullItems(items) {
        if (!this.data) {
            return [];
        }

        const fullItems = this.data.items;
        const fullItemsIndex = {};
        const result = [];

        const itemHash = function (item) {
            return item.modelId + "/" + item.id;
        };

        fullItems.forEach(item => {
            fullItemsIndex[itemHash(item)] = item;
        });

        items.forEach(item => {
            const hash = itemHash(item);

            if (fullItemsIndex[hash] === undefined) {
                logger.error("Failed to find full item", hash, item, fullItemsIndex);
                return true;
            }

            result.push(fullItemsIndex[hash]);
        });

        return result;
    }

    attached() {
        this.subscription = this.ea.subscribe('sio_form_post_submit', response => {

            if (response.config.modelId !== this._config.modelId) {
                return;
            }

            this.tableService.load();
        });
    }

    detached() {
        if (this.parentView.container.viewModel instanceof TabContentTable) {
            this.filterService.removeFiltersFromUrlParams();
        }
        this.subscription.dispose();
    }

    async bind() {
        this._defaultOptions = _.extend(this._defaultOptions, {
            searchBehavior: 'search-on-enter',
        });

        this.optionsChanged();
        this.tableService.additionalFetchConditions = this.additionalFetchConditions;
        this.contextObjectRefChanged();
        this.configChanged();
    }

    created(owningView, view) {
        this.parentView = owningView;
    }

    additionalFetchConditionsChanged() {
        this.tableService.additionalFetchConditions = this.additionalFetchConditions;

        this.tableService.load();
    }

    contextObjectRefChanged() {
        this.tableService.contextObjectRef = this.contextObjectRef;
    }

    optionsChanged() {
        // noinspection JSCheckFunctionSignatures
        logger.debug('Options changed', this.options);

        this._options = Object.assign({}, this._defaultOptions, this.options);
        this.selectService.multiSelect = this._options.multiSelect;
    }

    reloadWithCount()
    {
        this.tableService.request.loadCount = true;
        this.tableService.load();
    }

    configChanged(newValue, oldValue) {
        // noinspection JSCheckFunctionSignatures
        logger.debug('Configuration changed old -> new', oldValue, newValue);

        this.loading = true;

        this.configLoader.get(this.config, this.viewContext).then(async (config) => {

            this.rendererService.initialize(config);

            await this.viewSettingsService.initialize(config, this.viewContext);

            this.filterService.initialize(this, config, this.saveFiltersToUrl);

            this.tableService.config = config;
            this.tableService.saveFiltersToUrl = this.saveFiltersToUrl;
            this._config = config;

            // noinspection JSCheckFunctionSignatures
            logger.debug('Loaded configuration', this._config);

            if (config.defaultSort) {
                this.tableService.request.sort = config.defaultSort;
            }

            if (this.saveFiltersToUrl) {
                let sortBy = UrlUtils.getQueryParamValue(window.location.href, 'sortBy', false);
                if (sortBy) {
                    this.tableService.request.sort = JSON.parse(sortBy);
                }

                let offset = UrlUtils.getQueryParamValue(window.location.href, 'offset', false);
                if (offset) {
                    this.tableService.request.offset = offset;
                }

                let search = UrlUtils.getQueryParamValue(window.location.href, 'search', false);
                if (search) {
                    this.tableService.request.search = search;
                }
            }

            this.tableService.request.conditions = this.filterService.getConditions();

            this.__notifyAboutFiltersChange(this.tableService.request.conditions);

            this.tableService.load();

            this.loading = false;
        });
    }

    __notifyAboutFiltersChange(conditions) {
        if (this.additionalFetchConditions) {
            conditions = {
                '$and': [
                    conditions,
                    this.additionalFetchConditions
                ]
            };
        }

        this.onFiltersChanged(conditions);
    }

    searchBoxValueChange(event) {
        if (this.shouldSearch()) {
            console.debug('searchBoxValueChange', event);

            this.__search(event.target.value);
        }
    }

    searchBoxKeyUp(event) {
        if (this.shouldSearch(event.keyCode)) {
            this.__search(event.target.value);
        }
    }

    shouldSearch(keyCode) {
        if (this.isSearchImmediately()) {
            return true;
        }

        return !!(this.isSearchOnEnter() && keyCode === 13);
    }

    isSearchImmediately() {
        return !!(this._options && this._options.searchBehavior === 'search-immediately');
    }

    isSearchOnEnter() {
        return !!(this._options && this._options.searchBehavior === 'search-on-enter');
    }

    resetFilters() {
        // noinspection JSCheckFunctionSignatures
        logger.debug('resetFilters', this.filterService, this.tableService.request);

        this.filterService.reset();

        this.tableService.request.search = '';
        this.tableService.request.conditions = this.filterService.getConditions();
        this.tableService.request.offset = 0;
        this.data = [];

        this.tableService.load();
    }

    excel() {
        const request = _.cloneDeep(this.tableService.request);

        if (this.tableService.additionalFetchConditions && _.keys(this.tableService.additionalFetchConditions).length > 0) {
            request.conditions = {
                '$and': [
                    request.conditions,
                    this.tableService.additionalFetchConditions
                ]
            };
        }

        if (request.search) {
            request.conditions.search = request.search;
        }

        const params = {
            modelIdentifier: this._config.modelId,
            conditions: JSON.stringify(request.conditions),
            activeView: this._config.activeView.id
        };

        if (this.parentView && this.parentView.bindingContext && this.parentView.bindingContext.view) {
            params.title = this.parentView.bindingContext.view.title;
        }

        console.debug('Requesting Excel export', {params});

        return this.actions.getAction({
            type: 'workflow',
            workflowId: 'export/start-excel-export',
            formId: 'export/start-excel-export'
        }, {data: {formData: params}}).action();
    }
}
